import React from 'react';

import loadable from '@loadable/component';
import classNames from 'classnames';

import styles from './ModuleBuilder.css';
import { Section } from '../../../models/Strapi';


const MainModule = loadable(() => import('../MainModule/MainModule'));
const ColumnsModule = loadable(() => import('../ColumnsModule/ColumnsModule'));
const AccordionModule = loadable(() => import('../AccordionModule/AccordionModule'));
const GameInfoModule = loadable(() => import('../GameInfoModule/GameInfoModule'));
const InfoModule = loadable(() => import('../InfoModule/InfoModule'));
const ImageListModule = loadable(() => import('../ImageListModule/ImageListModule'));
const LinkListModule = loadable(() => import('../LinkListModule/LinkListModule'));
const ComingSoonGameModule = loadable(() => import('../ComingSoonGameModule/ComingSoonGameModule'));
// Constructs the Strapi modules
const ModuleBuilder = React.memo((props: { section: Section<unknown> }) => {
    const { section } = props;
    let result;

    switch (section.uid) {
        case 'MainModule':
            result = <MainModule {...section} />;
            break;
        case 'Columns':
            result = <ColumnsModule {...section} />;
            break;
        case 'AccordionModule':
            result = <AccordionModule {...section} />;
            break;
        case 'GameInfoModule':
            result = <GameInfoModule {...section} />;
            break;
        case 'InfoModule':
            result = <InfoModule {...section} />;
            break;
        case 'LinkListModule':
            result = <LinkListModule {...section} />;
            break;
        case 'ImageListModule':
            result = <ImageListModule {...section} />;
            break;
        case 'ComingSoonGameModule':
            result = <ComingSoonGameModule {...section} />;
            break;
        default:
            return <></>;
    }

    return (
        <div className={classNames(styles.container)}>
            {result}
        </div>
    );
});

export default ModuleBuilder;
